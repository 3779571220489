.careers{
  width: 100%;
  height: 500px;
}
.careers1 {
padding: 40px 0px;
}
.career-container, .career-container-fluid, .career-container-lg, .career-container-md, .career-container-sm, .career-container-xl {
width: 100%;
padding-right: 15px;
padding-left: 15px;
margin-right: auto;
margin-left: auto;
}
/* .career-row {
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
margin-right: -15px;
margin-left: -15px;
} */
.careers-row1 {
display: -ms-flexbox;
/* display: flex; */
-ms-flex-wrap: wrap;
flex-wrap: wrap;
margin-right: -15px;
margin-left: -15px;
}
.careers-row2 {
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;
margin-right: -15px;
margin-left: -15px;
}
.form-group {
margin-bottom: 1rem;
}
label {
display: inline-block;
margin-bottom: 0.5rem;
}
input,
button,
select,
optgroup,
textarea {
margin: 0; 
font-family: inherit;
 font-size:inherit;
line-height: inherit;
}
.col-md-6{
position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-md-12{
position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

}
#contact-form input {
width: 100%;
font-size: 14px;
margin-bottom: -1px;
padding: 4px 5px;
outline: none !important;
background-color: #fff;
border: 1px solid #212529;
color: #212529;
border-radius: 6px 6px 6px 6px;
}
.form-control {
display: block;
width: 100%;
height: calc(1.8em + 0.75rem + 2px);
padding: 2.375rem 0.75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #495057;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: 0.25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.fa-arrow-right:before {
content: "\f061";
color: #881008;
}
.form-control:focus {
color: #495057;
background-color: #fff;
border-color: #000 !important;
outline: 0;
box-shadow: none !important;
}
.text{
  text-align:center;
  padding-left: 20%;
  font-size: 2.5rem;
  color: #881008;
  margin-left: -20%;
}
.text4 p{
color: #000;
}
.register-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
}

.register-form label {
display: block;
margin-bottom: 10px;
}

.register-form input {
width: 100%;
padding: 10px;
margin-bottom: 20px;
border: 1px solid #ccc;
border-radius: 3px;
}

.button {
width: 30%;
padding: 10px;
margin-left: 35%;
background-color: #881008;
color: #fff;
border: none;
border-radius: 3px;
cursor: pointer;
}
.area{
width:105%
}
.text4{
text-align: left;
padding-left: 20%;
font-size:25px;
font-weight: 400;
}
.text5{
text-align: left;
padding-left: 20%;
font-size: 2.5rem;
color: #881008;
}
.style{
font-size: '22px';
color:' #e3097e'
}
.arrow-item {
font-size: '22px';
/* color:#0056b3; */
list-style: none;
margin: 20px 0;
margin-left: -2%;
margin-right: 10%;
}

.arrow-item::before {

  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
color:#881008;
/* content: "\f061"; */
}
.career-btn input {
background-color: #881008 !important;
color: #fff !important;
border: 1px solid #881008 !important;
}
.btn {
display: inline-block;
font-weight: 400;
color: #212529;
text-align: center;
vertical-align: middle;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
background-color: transparent;
border: 1px solid transparent;
padding: 0.375rem 0.75rem;
font-size: 1rem;
line-height: 1.5;
border-radius: 0.25rem;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
@media only screen and (max-width: 768px) {
.careers{
 
  height: 300px;
} 
.text5{
margin-left: -15%;
margin-top: 0%;
text-align: center;
color: #881008;
}
.careers1{
margin-top: -10%;
}
.text4{
margin-left: -20%;
text-align: left;
}
.text4 p{
margin-left: 10%;
text-align: left;
}
}