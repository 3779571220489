.ModalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  margin-top: 3%;
}

.left-side-d {
  flex: 1 1 40%; /* Take up 40% of the container's width initially */
  padding: 20px;
  margin-top: 10%;
}

.right-side-d {
  flex: 1 1 50%; /* Take up 50% of the container's width initially */
  padding: 20px;
}
.login-error-message{
  color: red;
text-align: center;
}
.vender-text {
  font-size: 3vw; /* Responsive font size */
  font-weight: 700;
  color: brown;
}
.password-input-container {
  position: relative;
}
.password-input-container1 {
  position: relative;
}
.password-input-container2 {
  position: relative;
}

/* .password-input-container input {
  padding-right: 40px; 
} */

.password-input-container button {
  position: absolute;
  top: 50%;
  right: 5px; /* Adjust as needed */
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}
.password-input-container1 button {
  position: absolute;
  top: 50%;
  right: 5px; /* Adjust as needed */
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}
.password-input-container2 button {
  position: absolute;
  top: 50%;
  right: 5px; /* Adjust as needed */
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.password-input-container button img {
  width: 20px; /* Adjust as needed */
  margin-left: -420%;
  margin-top: -82%;
}
.password-input-container1 button img {
  width: 20px; /* Adjust as needed */
  /* margin-left: 60%;
  margin-top: -112%; */
}
.password-input-container2 button img {
  width: 20px; /* Adjust as needed */
  /* margin-left: 60%;
  margin-top: -122%; */
}
.password-input-container1 button {
  position: absolute;
  right: 10px; /* Adjust the right distance as needed */
  top: 50%; /* Position the icon vertically centered */
  transform: translateY(-80%);
  left:80%;
}
.password-input-container2 button {
  position: absolute;
  right: 10px; /* Adjust the right distance as needed */
  top: 50%; /* Position the icon vertically centered */
  transform: translateY(-80%);
  left:80%;
}

.dimg-container {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
}

.dlogin-button, .dlogin-button1 {
  display: block;
  width: 20%; /* Make buttons fill their container */
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.left-side-d button {
  background-color: brown;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  display: inline;
  margin: 5px;
}

.left-side-d button:hover {
  background-color: black;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .flex-container {
    flex-direction: column; /* Stack items vertically */
    margin-top: 15%;
  }
  .vender-text{
    font-size: 8vw;
  }
  .left-side-d, .right-side-d {
    flex-basis: 10%; /* Take up full width */
  }
  .left-side-d button{
    width:30%;
  }
}

.Model_1 {
  width: 40%; 
  height: auto; 
  margin: auto; 
  overflow: auto; 
  background-color:brown ; 
  padding: 20px; 
  border-radius: 8px; 
  position: absolute; /* Position the modal relative to the container */
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  z-index: 1000;
  box-shadow: 10px 10px 5px 0px #888888;
}
.Model_2 {
  width: 40%; 
  height: auto; 
  margin: auto; 
  overflow: auto; 
  background-color:brown ; 
  padding: 20px; 
  border-radius: 8px; 
  position: absolute; /* Position the modal relative to the container */
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  z-index: 1000;
  box-shadow: 10px 10px 5px 0px #888888;
}
/* Form styles */
.signup_quote_form {
  width: 80%;
  margin: 0 auto;
}
.signin_quote_form {
  width: 80%;
  margin: 0 auto;
}
.input-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.input-wrapper {
  flex: 1;
  margin-right: 10px; /* Add margin between input wrappers */
}

.quote_label {
  margin-bottom: 5px;
  color: white;
}
.signin_quote_label {
  margin-bottom: 5px;
  color: white;
  margin-left: 10%;
}
.quote_button_1{
  margin-left: 30%;
}
.vlogin_close{
  margin-left: 1%;
}
.signup_quote_input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 10px;
}
.signin_quote_input {
  width: 80%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 10px;
  margin-left: 10%;
}

/* Button styles */
.quote_button {
  background-color: #45a049;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.quote_button:hover {
  background-color: #1b3c18;
}

/* Responsive styles */
@media (max-width: 768px) {
  .password-input-container button img {
    width: 20px;
    margin-left: 140%;
    margin-top: -102%;
}
.password-input-container1 button img {
  width: 20px;
}
.password-input-container2 button img {
  width: 20px;
}
.password-input-container1 button {
  position: absolute;
  right: 10px; /* Adjust the right distance as needed */
  top: 20%; /* Position the icon vertically centered */
  transform: translateY(-50%);
  margin-left:8%;
}
.password-input-container2 button {
  position: absolute;
  right: 10px; /* Adjust the right distance as needed */
  top: 20%; /* Position the icon vertically centered */
  transform: translateY(-50%);
  margin-left:8%;
}
  .input-group {
    flex-direction: column;
    margin: 0;
  }
  .signup_quote_form {
    margin-top: -8%;
  }
  .Model_2 {
    width:70%;
    
  }
  .Model_1 {
    width:70%;
  }
  .signin_quote_input {
    width:120%;
    margin-left: -9%;
  }
  .signup_quote_input {
    margin-left:-10% ;
    width:120%;
    height:40px;
    margin: 0;
    margin-left: -7%;
  }
  .signin_quote_label {
    margin-left: -7%;
  }
  .quote_button_1 {
    margin-left: 5%;
  }
  .quote_label {
    margin: 0;
    font-size: medium;
    margin-left: -7%;
  }
  .quote_button_2{
    margin-left: -2%;
    padding: 5px;
  }
  .vsignup_close{
    padding: 5px;
  }
  .signup_buttons{
    margin-left: -30%;
  }
  .option-signup{
    flex: 1;
    margin-right: 10px; /* Add margin between input wrappers */
    width:20%;
  }
  
  /* .input-wrapper {
    width: 48%;
  } */
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .password-input-container button img {
    width: 20px;
    margin-left: -300%;
    margin-top: -102%;
}
.password-input-container2 button {
  position: absolute;
  right: 10px; /* Adjust the right distance as needed */
  top: 20%; /* Position the icon vertically centered */
  transform: translateY(-50%);
  margin-left:18%;
}
.password-input-container1 button {
  position: absolute;
  right: 10px; /* Adjust the right distance as needed */
  top: 20%; /* Position the icon vertically centered */
  transform: translateY(-50%);
  margin-left:18%;
}
.signup_quote_input option{
  width:20%
}

  .Model_2 {
    width:75%;
  }
  .signup_buttons{
    margin-left: -10%;
  }
  .flex-container {
    margin-top: 5%;
  }
  .left-side-d button{
    width: 20%;
  }
  .quote_button_1 {
    margin-left: 30%;
  }
  .signin_quote_input{
    width:80%;
    margin-left: 10%;
  }
  .signin_quote_label{
    margin-left: 10%;
  }
  .Model_1{
    width:60%
  }
  
}
@media screen and (min-width: 1280px) and (max-width: 1280px) {
  .Model_2 {
    width:75%;
  }
  .password-input-container2 button {
    position: absolute;
    right: 10px; /* Adjust the right distance as needed */
    top: 30%; /* Position the icon vertically centered */
    transform: translateY(-50%);
    margin-left:1%;
  }
  .password-input-container1 button {
    position: absolute;
    right: 10px; /* Adjust the right distance as needed */
    top: 30%; /* Position the icon vertically centered */
    transform: translateY(-50%);
    margin-left:1%;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1024px) {
  .Model_2 {
    width:55%;
    /* height: 530px; */
  }
  .password-input-container1 button img {
    width: 20px;
}

.password-input-container2 button {
  position: absolute;
  right: 10px; /* Adjust the right distance as needed */
  top: 30%; /* Position the icon vertically centered */
  transform: translateY(-50%);
  margin-left:-2%;
}
.password-input-container1 button {
  position: absolute;
  right: 10px; /* Adjust the right distance as needed */
  top: 30%; /* Position the icon vertically centered */
  transform: translateY(-50%);
  margin-left:-4%;
}
.password-input-container2 button img {
  width: 20px;
}
}
@media screen and (min-width: 320px) and (max-width: 320px) {
  .Model_2 {
    width:65%;
    height: 450px;
  }
  .password-input-container2 button {
    position: absolute;
    right: 10px; /* Adjust the right distance as needed */
    top: 14%; /* Position the icon vertically centered */
    transform: translateY(-50%);
    margin-left:2%;
  }
  .password-input-container1 button {
    position: absolute;
    right: 10px; /* Adjust the right distance as needed */
    top: 20%; /* Position the icon vertically centered */
    transform: translateY(-50%);
    margin-left:4%;
  }

}
@media screen and (min-width: 412px) and (max-width: 412px) {
  .quote_button_1 {
    margin-left: 10%;
  }
}
@media screen and (min-width: 280px) and (max-width: 280px) {
  .quote_button_1 {
    margin-left: -9%;
  }
  .password-input-container2 button {
    position: absolute;
    right: 10px; /* Adjust the right distance as needed */
    top: 14%; /* Position the icon vertically centered */
    transform: translateY(-50%);
    margin-left:2%;
  }
  .password-input-container1 button {
    position: absolute;
    right: 10px; /* Adjust the right distance as needed */
    top: 20%; /* Position the icon vertically centered */
    transform: translateY(-50%);
    margin-left:4%;
  }


}
@media screen and (max-width: 912px) {
  .flex-container {
    margin-top: 5%;
    flex-direction: column;
  }
  .Model_1{
    width:70%
  }
}

.forgotpassword{
  text-decoration: none;
  color: brown;
  font-size: 16px;
  font-weight: 700;
}
.forgot_icon{
  width:40px;
  height:40px;
  color: #14ad1c;
  background-color: white;
}
.quote_button_2{
  margin-left: 35%;
}
.vsignup_close{
  margin-left: 1%;
}
@media screen and (min-width: 820px) and (max-width: 916px) {
  .password-input-container2 button {
    position: absolute;
    right: 10px; /* Adjust the right distance as needed */
    top: 30%; /* Position the icon vertically centered */
    transform: translateY(-50%);
    margin-left:-2%;
  }
  .password-input-container1 button {
    position: absolute;
    right: 10px; /* Adjust the right distance as needed */
    top: 30%; /* Position the icon vertically centered */
    transform: translateY(-50%);
    margin-left:-2%;
  }

}
