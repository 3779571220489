.main-container {
  display: flex;
  justify-content: space-between;
  align-items: left;
  text-align: left;
  margin-left: 10%;
  margin-right: 10%;
  max-width: 100%; /* Adjust this based on your layout needs */
  height: 350px;
  overflow: hidden;
  
}
.my-container {
  max-width: 100%;
  overflow-y: hidden; 
  overflow-x: hidden; 
}


/* body {
  overflow: hidden;
} */

  .image-zoom-container {
    /* overflow: hidden; */
    position: relative;
    width: 100%; 
  }
  .main-container-leftside h1{
    color:#881008;
  }
  
  .zoomed-image {
    width: 100%;
    height: 500px;
    animation: zoom-in 3s alternate infinite; /* Adjust the animation duration and properties */
    transform-origin: center;
    /* overflow: hidden; */
  } 
  @keyframes zoom-in {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1); 
    }
}
.main-container{
  display: flex;
    justify-content: space-between;
    align-items: left;
    text-align: left;
   margin-left: 10%;
   margin-right: 10%;
   height:550px;
   width:50%;
}
.about-v{
  padding: 50px 0px;
    background-color: rgb(255, 255, 255);
    /* background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.2)), url(../images/pngs/abt-bg-img.jpg); */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}
.m-row{
  display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-left: 6%;
}
.m-row1 {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.vision{
  margin-top: -10%;
}
.mission{
  margin-top: -10%;
}
.m-text{
  padding-top: 5px;
}
.m-text h3 {
  font-size: 32px;
  color: #000;
  font-weight: 300 !important;
  margin-top:-2% ;
}
.ser-box-button a {
  display: block; /* Makes the anchor tag fill the container */
  width: 100%;
  height: 100%;
  text-decoration: none; /* Remove default underline */
  color: inherit; /* Inherit color from parent */
}
.m-text p{
  color: rgb(118, 118, 118);
    text-align: justify;
    width:90%;
    margin-left: 3%;
}
@media (min-width: 768px){
.m-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
}
@media (min-width: 576px){
.m-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
}
@media (min-width: 1200px){
.m-container1, .m-container1-lg, .m-container1-md, .m-container1-sm, .m-container1-xl {
    max-width: 1140px;
}
}
.vision h4{
  font-family: 'Oswald', sans-serif;
    color: #000000;
    font-weight: 600;
    font-size: 32px;
}
.vision p{
  color: rgb(118, 118, 118);
    text-align: justify;
    margin-top: -5%;
}
.mission h4{
  font-family: 'Oswald', sans-serif;
  color: #000000;
  font-weight: 600;
  font-size: 32px;
}
.mission p{
  color: rgb(118, 118, 118);
    text-align: justify;
    margin-top: -5%;
}
.m-button{
  z-index: 1;
    height: 30px;
    width: 120px;
    color: #fff;
    font-weight: 600;
    line-height: 30px;
    padding-right: 35px;
    border-radius: 30px;
    position: relative;
    font-family: "Oswald", sans-serif;
    background: #881008;
    padding: 10px 20px;
    border: 1px solid #fff;
    transition: 0.5s ease;
    font-size: 18px;
}
.m-button a{
  display: block; 
    width: 100%;
    height: 100%;
    text-decoration: none; /* Remove default underline */
    color: inherit;
}
.about-home-img {
  padding: 50px 0px;
    background-color: rgb(255, 255, 255);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.2));
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

@media (max-width: 991px){
.main_img1 {
    width: 350px;
    height: 265px;
}
}
.main_img1{
  width: 290px;
  height: 240px;
  border: 1px solid #eaeaea;
  background: #fff;
  padding: 5px;
  border-radius: 4px;
  object-fit: cover;
  position: absolute;
  margin-left: -15%;
  margin-top: -5%;
}
/* @media (max-width: 991px){
.main_img2 {
    width: 325px;
    height: 250px;
}
} */
/* @media (max-width: 1200px){
.main_img2 {
    width: 250px;
    height: 250px;
}
} */
@media (min-width: 992px){
.m-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
}
@media (min-width: 992px){
.m-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    margin-top: 5%;
}
}
@media (min-width: 576px){
.m-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
}
.main_img2{
  width: 300px;
    height: 250px;
    object-fit: cover;
    position: absolute;
    /* top: 140px; */
    border: 1px solid #eaeaea;
    /* right: 56px; */
    background: #fff;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: 4px;
    margin-top: 4%;
    margin-left: -6%;
}
.m-services{
  padding: 30px 0px;
    overflow: hidden;
    position: relative;
}
@media (min-width: 768px){
.m-container1, .m-container1-md, .m-container1-sm {
    max-width: 720px;
}
}
@media (min-width: 576px){
.m-container1, .m-container1-sm {
    max-width: 1140px;
}
}
.m-container1, .m-container1-fluid, .m-container1-lg, .m-container1-md, .m-container1-sm, .m-container1-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px){
.m-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
}
/* .m-12{
  position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
} */
.m-service-heading h2{
  font-family: 'Oswald', sans-serif;
    margin-top: -5%;
    color: #881008;
    font-size: 30px;
    font-weight: 500;
    padding: 25px 0px 30px 0px;
    text-align: center;
    font-weight: bold !important;
}
.m-heading-ser{
  font-weight: 700;
    position: relative;
    z-index: 2;
    text-decoration: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    padding: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
    display: inline;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: color 1s ease;
}
@media (min-width: 576px){
.m-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
}
.m-3 .m-6{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.m-3 {
  flex: 0 0 calc(25% - 30px); /* Adjust the width and padding */
  max-width: calc(25% - 30px); /* Adjust the width and padding */
  padding: 0 15px;
}
.m-4 {
  flex: 0 0 calc(40% - 30px); /* Adjust the width and padding */
  max-width: calc(40% - 30px); /* Adjust the width and padding */
  padding: 0 15px;
}
.ser-box {
  display: flex;
  flex-direction: column;
  height: 100%; /* Set height to 100% */
}

.ser-box img{
  width: 94%;
    margin: 0 auto;
    clip-path: polygon(0 0, 100% 0%, 100% 93%, 0% 100%);
    display: flex;
    border-radius: 12px 12px 0px 0px;
    height:200px;
}
@media (max-width: 991px){
.ser-box-text {
    height: auto !important;
}
}
/* @media (max-width: 1200px){
.ser-box-text {
    height: 290px !important;
}
} */
.ser-box-text{
  background-color: #fff;
    border: 1px solid #eee;
    padding: 50px 12px 12px 12px;
    margin-top: -40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
@media (max-width: 991px){
.ser-box-text h3 {
    font-size: 19px;
}
}
.ser-box-text h3 {
  font-size: 16px;
  color:#881008;
  margin: 0px;
  padding: 0px;
  text-align: left;
}
.ser-box-button{
  z-index: 1;
    height: 30px;
    width: 120px;
    color: #fff;
    font-weight: 600;
    line-height: 31px;
    padding-right: 35px;
    border-radius: 30px;
    position: relative;
    font-family: "Oswald", sans-serif;
    background: linear-gradient(98deg, #881008 1%, #c44b4b 100%);
    padding: 6px 13px;
    border: 1px solid #fff;
    transition: 0.5s ease;
    font-size: 12px;
    transition: 0.5s ease;
}
.ser-box-text p{
  text-align: left;
  margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
}
.main-bridge1{
  height:160px;
}
.main-bridge2{
  height:160px;
}
.meet_our_space {
  padding: 150px 0px 80px 0px;
  background-image: linear-gradient(45deg, #eee0, #fff0), url(../../images/yellow_banner1.jpg);
  /* background-position: center; */
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  margin: 0 auto;
  height:500px;
}
.m-container2{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.meet_our_space::after {
  position: absolute;
  content: '';
  bottom: 0px;
  left: 500px;
  width: 1450px;
  height: 635px;
  background-color: #eaeaea;
  z-index: 0;
  border-top-left-radius: 35px;
}
.m-row2{
  display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.meet_our_space_row {
  position: relative;
  z-index: 9;
}
.wedding_hands {
  position: relative;
}
.wedding_hands img{
  height: 250px;
  margin-top: 50%;
}
.meet_our_space_lft_cnt {
  margin-top: 40%;
}
.meet_our_space_lft_cnt h4{
  font-family: 'Oswald', sans-serif;
  margin-top: -10%;
  color: #881008;
  font-size: 40px;
  font-weight: 700;
  margin-left: 50%;
}
.abt_sec_home_btn {
  z-index: 1;
  height: 30px;
  width: 120px;
  color: #fff;
  font-weight: 600;
  line-height: 30px;
  padding-right: 35px;
  border-radius: 30px;
  position: relative;
  font-family: "Oswald", sans-serif;
  background: linear-gradient(98deg, #881008 1%, #c44b4b 100%);
  padding: 10px 20px;
  border: 1px solid #fff;
  transition: 0.5s ease;
  font-size: 18px;
}
.abt_sec_home_btn1 {
  z-index: 1;
  height: 30px;
  width: 120px;
  color: #fff;
  font-weight: 600;
  line-height: 30px;
  padding-right: 35px;
  border-radius: 30px;
  position: relative;
  font-family: "Oswald", sans-serif;
  background: linear-gradient(98deg, #881008 1%, #c44b4b 100%);
  padding: 10px 20px;
  border: 1px solid #fff;
  transition: 0.5s ease;
  font-size: 18px;
  margin-left: 50%;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev:before, .slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: #881008 !important;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: #ff0089 !important;
  margin-top: 30%;
}
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: #ff0089 !important;
}
.meet_our_space_slicbox img {
  width: 115%;
  margin: 0px 5px;
  height: 300px;
  margin-top: 20%;
  margin-left: 10%;
}
.our-clients-main-container {
  padding: 40px 0px 70px 0px;
  background: #f8f9fa;
  overflow: hidden;
}
.m-container3{
  width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.m-row3{
  display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}
.ourclients-main-heading-sec {
  text-align: center;
  margin-bottom: 35px;
}
.ourclients-main-heading-sec h2 {
  font-family: 'Oswald';
  margin-top: 10px;
  color: #881008;
  font-size: 25px;
}
.ourclients-main-heading-sec p {
  padding: 3px 450px;
}
.clientsview-fist-row-sec {
  animation: 30s linear 0s infinite normal none running animate_row1;
  -ms-animation: 30s linear 0s infinite normal none running animate_row1;
  margin-bottom: 40px;
}
.clients-logos-section {
  display: flex;
}
.client-sec {
  padding: 0px 20px;
  margin-right: 28px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 5px;
  background: #fff;
}
.client-sec img {
  /* filter: grayscale(1); */
  transition: .3s ease-in-out;
  width: 100px;
}
.clientsview-fist-row-sec {
  animation: 30s linear 0s infinite normal none running animate_row1;
  -ms-animation: 30s linear 0s infinite normal none running animate_row1;
  margin-bottom: 40px;
}
@keyframes animate_row1 {
0% {
  transform: translateX(20%) translateZ(0px);
}
100% {
  transform: translateX(0) translateZ(0px);
}
}
.clientsview-second-row-sec {
  animation: 30s linear 0s infinite normal none running animate_row2;
  -ms-animation: 30s linear 0s infinite normal none running animate_row2;
}
@keyframes animate_row2 {
  0% {
      transform: translateX(-20%) translateZ(0px);
  }
  100% {
      transform: translateX(0) translateZ(0px);
  }
  }
  .testimonial_sec {
    padding: 70px 0px;
    background: #ffffff;
    overflow: hidden;
    position: relative;
}
.m-container4{
  width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.m-row4{
  display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.testimonial_heading{
  margin-left: 25%;
}
.testimonial_heading h2 {
  font-family: 'Oswald';
  margin-top: 10px;
  color: #881008;
  font-size: 25px;
  text-align: center;
  font-weight: 700;
  margin-left: 32%;
}
.owl-carousel {
  display: none;
  position: relative;
  width: 100%;
}
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}
.owl-carousel {
  display: inherit !important;
}
.owl-carousel .owl-wrapper-outer {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.owl-carousel .owl-wrapper {
  display: none;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
}
.testimonial {
  margin: 50px 30px 30px 30px;
  text-align: center;
  position: relative;
}
.testimonial .description {
  padding: 30px;
  margin-bottom: 20px;
  border-left: 1px solid #881008;
  font-size: 15px;
  color: #727272;
  line-height: 33px;
  position: relative;
}
.testimonial .title {
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: #881008;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 15px 0;
  position: relative;
}
.testimonial .pic img {
  display: inline-block;
  width: 100px;
  height: 100px;
}
.testimonial:before, .testimonial .title:before {
  content: "";
  width: 1px;
  height: 20px;
  background: #dd097b;
  margin: 0 auto;
  position: absolute;
  top: -30px !important;
  left: 0;
  right: 0;
}
.testimonial:before, .testimonial .title:before {
  content: "";
  width: 1px;
  height: 20px;
  background: #881008;
  margin: 0 auto;
  position: absolute;
  top: -30px !important;
  left: 0;
  right: 0;
}
.testimonial .description:after {
  background: #881008;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20%;
}

.testimonial .description:after {
  bottom: 0;
  top: auto;
}
:after, :before {
  box-sizing: border-box;
}
.testimonial .title {
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: #881008;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 15px 0;
  position: relative;
}

.testimonial .description:before, .testimonial .description:after {
  content: "";
  width: 20%;
  height: 1px;
  background: #881008;
  position: absolute;
  top: 0;
  left: 0;
}
/* .testimonial .description:after {
  content: "";
  width: 20%;
  height: 1px;
  background: #881008;
  position: absolute;
  top: 0;
  left: 0;
} */
.testimonial {
  margin: 50px 30px 30px;
  position: relative;
  text-align: center;
}
.testimonial:before, .testimonial .title:before {
  content: "";
  width: 1px;
  height: 20px;
  background: #881008;
  margin: 0 auto;
  position: absolute;
  top: -30px !important;
  left: 0;
  right: 0;
}

.round-button {
  background-color: #fff;
  border: 2px solid #881008;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  font-size: 16px;
  color: #881008;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  outline: none;
  padding-left: 15px;
}

.round-button.active {
  background-color: #881008;
  color: #fff;
}
.testimonial .title:before{ top: -80px; }
.round-button:hover {
  background-color: #881008;
  color: #fff;
}
.testimonial-buttons{
  margin-left: 30%;
}
.wding_cps {
  position: relative;
  height: 400px;
}
.wding_cps img {
  margin: -15% -52% 0px -35px;
  height: 480px;
}
.m-5 .sm12{
  position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.m-7 .sm12{
  position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media screen and (max-width: 768px) {
  .zoomed-image {
    width: 100%;
    height:400px;
  }
  .m-text h3 {
    font-size: 24px;
    margin-left: -1%;
    margin-right: 10%;
  }
  .m-text p {
    margin-left: -1%;
  }
  .vision h4{
    margin-left: -85%;
  }
  .vision p{
    margin-left: -6%;
  }
  .mission h4{
    margin-left: -80%;
  }
  .mission p{
    margin-left: -6%;
  }
  .m-button{
    margin-left: -5%;
  }
  .main_img1{
    margin-left: 6%;
    height: 150px;
    width:50%;
  }
  .main_img2{
    /* display: none; */
    margin-left: 6%;
    height: 150px;
    width:50%;
    margin-top:22% ;
    margin-left: 20%;
  }
  .m-service-heading h2{
    margin-top: 50%;
    margin-left: 30%;
    white-space: nowrap;
  }
  
  .m-3 {
    flex: 0 0 100%; 
    max-width: 80%; 
    padding: 10px; 
    margin-left:4%;
    /* margin-top: -3%; */
  }
  .ourclients-main-heading-sec p{
   padding: 1px 25px;
   margin-left: -2%;
   text-align: justify;
  }
  .testimonial_heading{
    margin-left: -5%;
  }
  .testimonial_heading h2{
    margin-left: -35%;
  }
  .testimonial .description {
    width: 45%;
    margin-left: 6%;
  }
  .testimonial .title {
    margin-left: -35%;
  }
  .testimonial:before, .testimonial .title:before {
  padding-left: 1px;
  }
  .testimonial .pic img{
    margin-left: -35%;
  }
  .testimonial-buttons{
    margin-left: -35%;
  }
  .wding_cps img{
    margin-left: -70%;
    margin-top: 0%;
  }
  .meet_our_space_lft_cnt h4 {
    margin-left: 2%;
    margin-top: -45%;
  }
  .abt_sec_home_btn1 {
    margin-left: 24%;
    margin-top: -2%;
  }
  .m-4{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(90% - 30px);
    max-width: calc(90% - 30px);
    padding: 0 15px;
}
.meet_our_space_slicbox img{
  height: 200px;
  margin-left: 1%;
}
.wedding_hands img {
  display: none;
}
}
@media (max-width: 576px){
.meet_our_space::after {
    left: 0px;
    width: 98%;
    border-radius: 0px 30px 30px 0px;
    height: 680px;
}
}
@media (max-width: 576px){
.meet_our_space::after {
    left: 0px;
    width: 98%;
    border-radius: 0px 30px 30px 0px;
    height: 680px;
}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* .main-container1, .main-container1-md, .main-container1-sm {
    max-width: 720px;
} */
.m-text h3{
  font-size: 32px;
}
.vision{
  margin-top: -5%;
}
.vision h4{
  margin-left: -100%;
}
.mission h4{
  margin-left: -96%;
}
.mission{
  margin-top: -5%;
}
  .main_img1{
    margin-left:-45%;
    margin-top: 0%;
    height:220px;
    width:50%;
  }
  .main_img2{
    margin-left: -26%;
    height:220px;
    width:50%;
    margin-top: 20%;
  } 
  .m-6 {
    flex: 0 0 100%;
    max-width: 100%;
}
.m-service-heading h2{
  margin-top: 30%;
  margin-left: -10%;
  white-space: nowrap;
}
.m-3 {
  flex: 0 0 40%;
  max-width: 40%;
  padding: 10px;
  margin-left: 2%;
  /* margin-top: -3%; */
}
.meet_our_space::after {
  left: 30px;
}
.meet_our_space::after {
  left: 30px;
}
.meet_our_space_slicbox img {
  height: 250px;
  margin-left: -5%;
  width:60%;
  margin-top: 10%;
}
.meet_our_space_lft_cnt h4 {
  margin-left: -70%;
  margin-top: -45%;
}
.abt_sec_home_btn1 {
  margin-left: -15%;
  margin-top: -2%;
}
.ourclients-main-heading-sec p {
  padding: 1px 25px;
  margin-left: 2%;
  margin-right: 12%;
  text-align: justify;
}
.ourclients-main-heading-sec h2{
  margin-left: -8%;
}
.testimonial .description {
  width: 90%;
  margin-left: 6%;
  text-align: justify;
}
.testimonial_heading h2 {
  margin-left: 2%;
}
.testimonial .title {
  margin-left: 5%;
}
.testimonial .pic img {
  margin-left: 5%;
}
.testimonial-buttons {
  margin-left: 0%;
}
.wding_cps img {
  margin-left: -10%;
  margin-top: 0%;
}
}
/* @media (max-width: 1220px){
.meet_our_space::after {
    left: 30px;
}
} */
@media screen and (min-width: 1024px) and (max-width: 1024px) {
  .m-text h3{
    margin-left: -20%;
  }
  .vision h4 {
    margin-left: -96%;
}
.main_img2 {
    margin-top: 12%;
}
.vision p{
  margin-left: -3%;
}
.mission h4 {
  margin-left: -93%;
}
.mission p{
  margin-left: -3%;
}
.main_img1{
  margin-left:-45%;
  margin-top: 0%;
  height:220px;
  width:40%;
}
.main_img2{
  margin-left: -26%;
  height:220px;
  width:40%;
  margin-top: 15%;
}
.wedding_hands img{
  display:none;
} 
.meet_our_space_slicbox img{
  margin-top: 3%;
}
.testimonial_heading h2 {
  margin-left: 25%;
}
.wding_cps img{
  margin-left: 62%;
}
.testimonial-buttons{
  margin-left: 35%;
}
}
@media screen and (min-width: 820px) and (max-width: 916px) {
  .wedding_hands img{
    display: none;
  }
  .m-row4{
    margin-left: -15%;
  }
  .testimonial_heading h2 {
    margin-left: 25%;
  }
  .testimonial-buttons{
    margin-left: 35%;
  }
  .wding_cps img{
    margin-left: 62%;
  }
  .vision h4 {
    margin-left: -94%;
}

.vision p{
  margin-left: -3%;
}
.mission h4 {
  margin-left: -91%;
}
.mission p{
  margin-left: -3%;
}
}
@media screen and (min-width: 1280px) and (max-width: 1280px) {
  .meet_our_space_slicbox img{
    margin-left: 30%;
    width:115%;
  }
  .meet_our_space_lft_cnt h4{
    margin-left: 70%;
  }
  .abt_sec_home_btn1{
    margin-left: 70%;
  }
}
