.navbar {
  background-color:#881008;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  position: fixed;
  flex-direction: row;
  /* margin-right: 1%; */
  /* margin-left: -2%; */
  width: 102%;
  top: 0;
  z-index: 1000;
}

.header-container {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* background-color: #881008; */
  color: #fff;
  padding: 5px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  margin-left: -1%;
}
.navbar-menu{
  margin-left: 12%;
}
.toggle-button {
  background: #881008;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  display: none; /* Initially hidden */
}
.navbar-menu li .active {
  color: yellow; /* Change color to green for active link */
  font-size: x-large;
}
@media (max-width: 768px) {
  .vender-button{
    margin-left: 1%;
  }
  /* .header-container {
    width: 38%;
  } */
  .toggle-button {
    display: block; 
    margin-left: 1%;
  }
  .navbar-menu {
    list-style: none;
    flex-direction: column; /* Stack items vertically */
    height: 400px; 
    margin-left: 1%;
  }
  .header-container.menu-open {
    background: #881008;
    min-height: 45px;

  }
  .toggle-button-container{
    margin-top: 30px;
  }
  .navbar.visible{
  background: #881008;
  
  }
  .navbar-menu li{
    list-style: none;
    /* display: flex; */
    padding:8px;
    text-align: center;
    /* font-size: larger; */
    /* margin-top: -2%; */
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    display: block;
    /* text-transform: uppercase; */
    /* line-height: 29px; */
    font-family: 'Cinzel Decorative';
    margin-left: -65%;
  }
  .navbar {
    display: none;
    margin-top: -2%;
    background-color:transparent;
     position: fixed;
  }
  .button1:hover {
    background-color:transparent; 
  }
  /* .toggle-button span{
    position:absolute;
  } */
  .navbar.visible {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* background-color: #881008; */
    position: fixed;
    /* width: 38%; */
    top: 60px;
    z-index: 999;
    margin-left:-3%;
  }
  @-webkit-keyframes wsgdrcolors {
    0% {
      background-position: 0% 90%
    }
  
    50% {
      background-position: 100% 11%
    }
  
    100% {
      background-position: 0% 90%
    }
  }
  
  @-moz-keyframes wsgdrcolors {
    0% {
      background-position: 0% 90%
    }
  
    50% {
      background-position: 100% 11%
    }
  
    100% {
      background-position: 0% 90%
    }
  }
  
  @keyframes wsgdrcolors {
    0% {
      background-position: 0% 90%
    }
  
    50% {
      background-position: 100% 11%
    }
  
    100% {
      background-position: 0% 90%
    }
  }
  .nav_scroll_pngs {
    padding: 0px 20px;
}
.nav_scroll_pngs {
  max-width: 940px;
  margin: 0 auto;
  text-align: center;
}
.footer-marquee {
  border-radius: 1px 7px 1px 13px;
  border-left: 4px solid #fa6870;
  border-right: 4px solid #fa676f;
  overflow: hidden;
  white-space: nowrap;
  direction:left;
  margin-left: -3%;
  margin-right: 70%;
  margin-top: 3%;
}
:host {
  display: inline-block;
  overflow: hidden;
  text-align: initial;
  white-space: nowrap;
}
.footer-marquee img {
  height: 70px;
  animation: marquee 10s linear infinite;
  /* margin-right: 10px; */
} 

@keyframes marquee {
  100% {
    transform: translateX(100); /* Start from 0 */
  }
  100% {
    transform: translateX(calc(-10% - 800px)); /* Move to the left by total width of images including margin */
  }
}
.background-overlay {
  position: fixed;
  top: 60px; /* Adjust according to your navigation bar height */
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 999;
  display: none;
}

.menu-open .background-overlay {
  display: block;
}
/* .footer-marquee img:nth-child(4) {
  animation-delay: 190s; 
} */
.navbar-menu ul li {
 padding-right: -20%;
}

}

.navbar-logo {
  font-size: 24px;
}

.navbar-menu {
  list-style: none;
  display: flex;
  /* height:40px; */
}

.navbar-menu  li {
  margin-right: 10%;
  /* margin-left: 15%; */
  padding-left: 5%;
  
}

.navbar.scrolling {
  background-color: none; 
}
.navbar-menu a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
  text-decoration: none;
}

.navbar-menu li:hover {
  color: #FFFFFF;
  transform: scale(1.4); /* Adjust the scale factor as needed */
  transition: transform 0.3s ease; /* Add a smooth transition */
}
/* body {
  padding-top: 70px; 
} */
.vender-button{
  margin-top: -5%;
  margin-bottom: -9%;
  font-weight: 600;
background-color: #FFFFFF;
color: #881008;
height:30px;
padding-bottom:25px;
}
.vender-button:hover{
  background-color: #FFFFFF;
color: #881008;
/* transform: scale(1.1); */
}
/* toggle bar */
.homebg{
  position: relative;
    z-index: 92;
}
.bg {
  width: 100%;
}
.h-logo {
  padding: 0px 0px 0px 0px;
  position: absolute;
  left: 20px;
  top: 20px;
}
.clearfix {
  zoom: 1;
}
.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}
@media (max-width: 425px){
.h-logo img {
    width: 60px;
}
}
.clearfix:after {
  clear: both;
}
.h-logo::after {
  position: absolute;
  top: -30px;
  left: -30px;
  width: 170px;
  height: 170px;
  content: '';
  z-index: 1;
  background-color: #881008;
  border-radius: 0px 50% 50% 50%;
  animation: scale1 4s linear infinite alternate-reverse;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  animation-name: shapeMover2;
}
.h-logo img {
  position: relative;
  z-index: 2;
  margin-left: -7px;
  margin-top: -7px;
  width: 130px;
  border-radius: 50%;
}
.toggle-button {
  background: none;
  border: none;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  display: flex;
  margin-top: -3%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: fixed;
  z-index: 999;
}

.toggle-button .bar {
  width: 30px;
  height: 3px;
  background-color: #fff;
  margin: 2px;
}
.toggle-button.close .bar {
  display: none;
  width: 30px;
  height: 15px;
}

.toggle-button.close span {
  font-size: 44px;
  /* height:10px; */
  color: #fff;
  margin-left: 25px;
  margin-top:-49% ;
}
@media screen and (max-width: 1200px){
.nav_scroll_pngs {
    padding: 0px 20px;
}
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .navbar-menu li {
  margin-left: -26%;
  }
  .navbar.visible {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* background-color: #881008; */
    position: fixed;
    /* width: 38%; */
    top: 60px;
    z-index: 999;
    margin-left: -3%;
}
.navbar.visible {
  background: #881008;
}
  .toggle-button {
    display: block; 
    margin-left: 1%;
  }
  .toggle-button.close span {
    font-size: 44px;
    /* height:10px; */
    color: #fff;
    margin-left: 25px;
    margin-top:-95% ;
  }
  .header-container.menu-open {
    background: #881008;
    min-height: 73px;
}
  .footer-marquee{
    margin-left: -1%;
    margin-right: 30%;
  }
  .navbar.visible{
    margin-top: 20px;
  }
  .toggle-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    display: flex;
    margin-top: 3%;
    margin-left: 7%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  .navbar-menu {
    list-style: none;
    flex-direction: column; /* Stack items vertically */
    height: 400px; 
    margin-left: 25%;
  }
  .header-container.menu-open {
    background: #881008;
    height:50px;
  }
  .toggle-button .bar {
    width: 30px;
    height: 3px;
    background-color: #fff;
    margin: 2px;
  }
  .toggle-button.close .bar {
    display: none;
    width: 30px;
    height: 15px;
  }
  
  .toggle-button.close span {
    font-size: 44px;
    /* height:10px; */
    color: #fff;
    margin-left: 25px;
  }
  .nav_scroll_pngs {
    padding: 0px 20px;
}
.nav_scroll_pngs {
  max-width: 940px;
  margin: 0 auto;
  text-align: center;
}
.footer-marquee {
  border-radius: 1px 7px 1px 13px;
  border-left: 4px solid #fa6870;
  border-right: 4px solid #fa676f;
  overflow: hidden;
  white-space: nowrap;
  direction:left;
  margin-left: -17%;
  margin-right: 50%;
  margin-top: 3%;
}
:host {
  display: inline-block;
  overflow: hidden;
  text-align: initial;
  white-space: nowrap;
}
.footer-marquee img {
  height: 70px;
  animation: marquee 10s linear infinite;
  /* margin-right: 10px; */
} 

@keyframes marquee {
  100% {
    transform: translateX(100); /* Start from 0 */
  }
  100% {
    transform: translateX(calc(-10% - 800px)); /* Move to the left by total width of images including margin */
  }
}
.navbar-menu li{
  list-style: none;
  /* display: flex; */
  padding:8px;
  text-align: center;
  /* font-size: larger; */
  /* margin-top: -2%; */
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  display: block;
  /* text-transform: uppercase; */
  /* line-height: 29px; */
  font-family: 'Cinzel Decorative';
  margin-left: -60%;
}
.navbar {
  display: none;
  margin-top: -2%;
  background-color:transparent;
}
button3:hover {
  background-color:transparent; 
}
.navbar.visible {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  /* background-color: #881008; */
  position: fixed;
  /* width: 38%; */
  top: 60px;
  z-index: 999;
  margin-left:-3%;
}
@-webkit-keyframes wsgdrcolors {
  0% {
    background-position: 0% 90%
  }

  50% {
    background-position: 100% 11%
  }

  100% {
    background-position: 0% 90%
  }
}

@-moz-keyframes wsgdrcolors {
  0% {
    background-position: 0% 90%
  }

  50% {
    background-position: 100% 11%
  }

  100% {
    background-position: 0% 90%
  }
}

@keyframes wsgdrcolors {
  0% {
    background-position: 0% 90%
  }

  50% {
    background-position: 100% 11%
  }

  100% {
    background-position: 0% 90%
  }
}
}
@media screen and (min-width: 1024px) and (max-width: 1024px) {
  .footer-marquee{
    display: none;
  }
  .navbar-menu li{
    margin-left: -6%;
  }
  .navbar-menu{
    margin-left: 1%;
  }
}
@media screen and (min-width: 320px) and (max-width:320px) {
  .footer-marquee{
    margin-right: 75%;
  }
  .navbar-menu li {
    margin-left: -65%;
  }
  
}
@media screen and (min-width: 430px) and (max-width:430px) {
  .toggle-button.close span{
    font-size: 44px;
    /* height: 10px; */
    color: #fff;
    margin-left: 25px;
    margin-top: -49%;
  }
  .toggle-button{
    margin-top: 2%;
    position: fixed;
  }
}
@media screen and (min-width: 540px) and (max-width:540px) {
  .footer-marquee{
    margin-right: 50%;
  }
  .navbar-menu li {
    margin-left: -45%;
  }
}
@media screen and (min-width: 280px) and (max-width:280px) {
  .footer-marquee{
    margin-right: 80%;
  }
  .navbar-menu li {
    margin-left: -70%;
  }
}
/* @media screen and (min-width: 912px) and (max-width:912px) {
  .navbar-menu li{
    margin-left: 1%;
    padding-left: 2px;
    margin-right: 2%;
  }
}
@media screen and (min-width: 820px) and (max-width:820px) {
  .navbar-menu li{
    margin-left: 1%;
    padding: 2px;
    margin-right: 2%;
  }
  .navbar{
    margin-left: -8%;
    width:110%;
  }
}
@media screen and (min-width: 853px) and (max-width:853px) {
  .navbar-menu li {
    margin-left: -6%;
    margin-right: 22%;
}
  .navbar{
    margin-left: -8%;
    width:110%;
  }
} */
@media screen and (min-width: 1280px) and (max-width: 1280px) {
  .navbar{
    width:100%;
  }
  .navbar-menu{
    margin-left: 2%;
  }
}
