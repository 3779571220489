/* about.css */

/* Default styles */

.about-img1 {
  width: 100%;
  height: 500px; 
}
.about-heading h4{
 
    margin-top: 20px;
    color: #881008;
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    margin-left: 20%;
}

.event-list {
  list-style-type: none;
  padding-left: 0;
}

.event-list li {
  text-decoration: none;
  list-style-type: none; 
  text-align: left;
  font-size: 20px;
  margin-left: 20%;
}
.about_img2{
  width:50%;
  margin-top: -5%;
  margin-left: -40%;
  border: 1px solid #eaeaea;
  background: #fff;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 4px;
}
img {
  border-style: none;
  vertical-align: middle;
}
.about_img3{
  width:50%;
  margin-top: 20%;
  margin-left: -30%;
  border: 1px solid #eaeaea;
  background: #fff;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 4px;
}
.about-text{
  display: flex;
  flex-direction: row;
}
.about-heading p{
  width: 60%;
  text-align: left;
  margin-left: 20%;
  margin-top: -2%;
}
.about-heading h5 span{
  border-bottom: 1px solid #000;
  font-size: 25px;
  text-align: left;
  margin-left: 20%;
}
.about-heading h5{
  text-align: left;
  margin-left: 0%;
}
.about-container2 h1{
  text-align: left;
  margin-left: 12%;
color:#881008;
}
.about-container2 p{
text-align: left;
margin-left: 12%;
margin-right: 12%;
}
.about-container2 i{
  color:#881008;
}
.about-container3 i{
  color:#881008;
}
.about-container3 h1{
 text-align: left;
 margin-left: 12%;
color:#881008;
}
.about-container3 p{
  text-align: left;
  margin-left: 12%;
  margin-right: 12%;
  }
/* Tablet styles */
@media screen and (max-width: 1024px) {
  .about-img1 {
      max-width: 100%; /* Limit image width on tablets */
      height: 400px;
  }
  .about-container2 h1{
  font-size: 25px;
  margin-left: 9%;
  }
  .about-container3 h1{
    font-size: 25px;
    margin-left: 9%;
    }
  .about-heading h5 span{
    font-size: 20px;
  }
  .about-text{
    display: flex;
    flex-direction: column;
  }
  .about_img2{
    margin-left: -6%;
    margin-top: -16%;
  }
  .about_img3{
    margin-top: 22%;
  }
  .about-heading h4{
    font-size: 30px;
    margin-left: 8%;
  }
  .about-heading p {
    width:80%;
    margin-left: 8%;
  }
  .about-heading h5{
    font-size: 20px;
    text-align: left;
    margin-left: -14%;
  }
}

/* Mobile styles */
@media screen and (max-width: 767px) {
  .about-img1 {
      max-width: 100%;
      height: 300px;
  }
  .about-container2 h1{
    font-size: 20px;
    margin-right: 10%;
    margin-left: 9%;
    }
    .about-heading h4{
      font-size: 25px;
      margin-left: 8%;
    }
    .about-heading h5{
      font-size: 20px;
      text-align: left;
      margin-left: -12%;
    }
    .about-heading h5 span{
      font-size: 18px;
       margin-left: 19%;
    }
    .about-container3 h1{
      margin-left: 9%;
      font-size: 20px;
}
.about_img2{
  margin-top: 0%;
  height: 300px;
  width:80%;
  margin-left: 0%;
}
.about_img3{
  display:none;
}
.about-text{
  display: flex;
  flex-direction: column;
}
.about-heading p {
  width:80%;
  margin-left: 8%;
}
}

@media screen and (min-width: 1280px) and (max-width: 1280px) {
  .about_img2{
    margin-left: -56%;
    width:60%;
    
  }
  .about_img3{
    width:60%;
  }
}
